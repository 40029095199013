<script lang="ts">
	export let direction: 'x' | 'y' = 'y';
</script>

<span class="inline-block" class:rotate-90={direction === 'x'}>
	<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5 1L9 5M5 1L1 5M5 1V18M14 19L18 15M14 19L10 15M14 19V2"
			stroke="#0F0F0F"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</span>
