class SessionWrapper {
	static sessionToken: google.maps.places.AutocompleteSessionToken;

	async init(regenerate: boolean) {
		if (!SessionWrapper.sessionToken || regenerate) {
			// eslint-disable-next-line no-empty-pattern
			await google.maps.importLibrary('places');
			SessionWrapper.sessionToken = new google.maps.places.AutocompleteSessionToken();
		}
	}
}

export async function getSessionToken(regenerate = false) {
	if (!google.maps) await google.maps.importLibrary('places');
	await new SessionWrapper().init(regenerate);
	return SessionWrapper.sessionToken;
}
