<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M14 7L17.4649 9C17.8052 8.41165 18 7.72857 18 7C18 4.79086 16.2092 3 14 3C12.5195 3 11.2268 3.8044 10.5352 5L14 7ZM14 7L12 12M19 17.6098C19 17.6098 17.8161 14.1468 16 13C14.6386 12.1403 13.6102 12 12 12M19 17.6098C18.1824 17.8183 17.2537 18 16.5 18C15.2873 18 13.6214 17.5296 12.6943 17.2341C12.2434 17.0903 11.7569 17.0905 11.306 17.2341C10.386 17.5272 8.73513 17.9922 7.5 18C6.72683 18.0049 5.78772 17.8211 4.96783 17.6098M19 17.6098C20.1007 17.3292 21 17 21 17M12 12C10.3898 12 9.36144 12.1403 8 13C6.18389 14.1468 4.96783 17.6098 4.96783 17.6098M4.96783 17.6098C3.87874 17.3292 3 17 3 17"
		stroke="url(#paint0_linear_1137_35484)"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_1137_35484"
			x1="3"
			y1="3"
			x2="20.9156"
			y2="6.36306"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
