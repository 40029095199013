<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M20 6.17157V5.70711C20 5.25435 19.8201 4.82014 19.5 4.5C19.1799 4.17986 18.7456 4 18.2929 4H17.8284C17.298 4 16.7893 4.21071 16.4142 4.58579L13 8L5.52982 4.26491C5.19629 4.09815 4.80371 4.09815 4.47018 4.26491C3.74691 4.62655 3.59048 5.59048 4.16228 6.16228L9.5 11.5L6.56749 14.4325C6.20413 14.7959 5.71132 15 5.19745 15H4.78031C4.30209 15 3.8649 15.2702 3.65103 15.6979C3.30742 16.3852 3.65214 17.2174 4.38105 17.4604L5.09256 17.6975C5.66381 17.8879 6.11206 18.3362 6.30248 18.9074L6.53965 19.6189C6.78262 20.3479 7.61485 20.6926 8.30207 20.349C8.72981 20.1351 9 19.6979 9 19.2197V18.8025C9 18.2887 9.20413 17.7959 9.56749 17.4325L12.5 14.5L17.8377 19.8377C18.4095 20.4095 19.3735 20.2531 19.7351 19.5298C19.9019 19.1963 19.9019 18.8037 19.7351 18.4702L16 11L19.4142 7.58579C19.7893 7.21071 20 6.70201 20 6.17157Z"
		stroke="url(#paint0_linear_1137_35461)"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_1137_35461"
			x1="3"
			y1="4"
			x2="20.0981"
			y2="6.67468"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
