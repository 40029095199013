import type { AutocompletePrediction } from '$types/autocomplete.types';
import { PALMA_LOCATION_RESTRICTION } from './defaults';
import { inferType } from './utils';

class AutoCompleteWrapper {
	private static service: google.maps.places.AutocompleteService;

	async init() {
		if (!AutoCompleteWrapper.service) {
			const initService = async () => {
				if (window.googleMapsLoaded) {
					const { AutocompleteService } = (await google.maps.importLibrary(
						'places'
					)) as google.maps.PlacesLibrary;
					AutoCompleteWrapper.service = new AutocompleteService();
				} else {
					setTimeout(initService, 100);
				}
			};
			let attempts = 0;
			const maxAttempts = 50; // 5 seconds maximum wait time
			const attemptInitService = async () => {
				await initService();
				if (!AutoCompleteWrapper.service && attempts < maxAttempts) {
					attempts++;
					setTimeout(attemptInitService, 100);
				} else if (!AutoCompleteWrapper.service) {
					throw new Error('Failed to initialize AutocompleteService after multiple attempts');
				}
			};
			await attemptInitService();
		}
	}

	async getPredictions(input: string, sessionToken: google.maps.places.AutocompleteSessionToken) {
		return AutoCompleteWrapper.service
			.getPlacePredictions({
				input,
				sessionToken: sessionToken,
				// Bias results to prefer locations/places in Spain / Palma
				componentRestrictions: { country: 'es' },
				locationRestriction: PALMA_LOCATION_RESTRICTION
			})
			.then((results) => {
				return results.predictions;
			});
	}
}

export async function autoComplete(
	input: string,
	sessionToken: google.maps.places.AutocompleteSessionToken
) {
	if (!input) return;
	const wrapper = new AutoCompleteWrapper();
	await wrapper.init();
	return wrapper.getPredictions(input, sessionToken).then((results) => {
		const predictions: AutocompletePrediction[] = Array.from({ length: results.length });
		for (let i = 0; i < results.length; i++) {
			const { main_text, secondary_text } = results[i].structured_formatting;
			predictions[i] = {
				place_id: results[i].place_id,
				structured_formatting: {
					main_text,
					secondary_text
				},
				type: inferType(results[i].types)
			};
		}
		return predictions;
	});
}
