<script lang="ts">
	export let variant: 'gradient' | 'solid' = 'gradient';
</script>

{#if variant === 'gradient'}
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20 10C20 12.8255 15.9937 17.3967 13.8373 19.6526C13.1009 20.4229 11.8991 20.4229 11.1627 19.6526C9.00627 17.3967 5 12.8255 5 10C5 6.13401 8.35786 3 12.5 3C16.6421 3 20 6.13401 20 10ZM12.5 13C14.2752 13 15.7143 11.6569 15.7143 10C15.7143 8.34315 14.2752 7 12.5 7C10.7248 7 9.28571 8.34315 9.28571 10C9.28571 11.6569 10.7248 13 12.5 13Z"
			stroke="url(#paint0_linear_1137_35472)"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1137_35472"
				x1="5"
				y1="3"
				x2="20.1975"
				y2="4.98114"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#D7DBFF" />
				<stop offset="1" stop-color="#C2C8FF" />
			</linearGradient>
		</defs>
	</svg>
{:else}
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19 10C19 12.7645 15.4207 17.2 13.381 19.5031C12.6388 20.3412 11.3612 20.3412 10.619 19.5031C8.57932 17.2 5 12.7645 5 10C5 6.13401 8.13401 3 12 3C15.866 3 19 6.13401 19 10ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
{/if}
