const variants = ['pickup', 'dropoff', 'displayPickup', 'displayDropoff'] as const;
export type MapVariant = (typeof variants)[number];

export const PickupMapVariants: MapVariant[] = ['pickup', 'displayPickup'];
export const DropoffMapVariants: MapVariant[] = ['dropoff', 'displayDropoff'];

export const DisplayMapVariants: MapVariant[] = ['displayPickup', 'displayDropoff'];

export type Bounds = {
	north: number;
	south: number;
	east: number;
	west: number;
};
