<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M9 11L10.0001 3M15 11L14.0001 3M19.0001 11V11C20.016 11 20.7613 10.0453 20.515 9.05982L19.3788 4.51495C19.1563 3.62461 18.3563 3 17.4385 3H14.0001M19.0001 11V19C19.0001 20.1046 18.1047 21 17.0001 21H14.0001M19.0001 11H5.00012M5.00012 11V11C3.98416 11 3.23871 10.0452 3.48513 9.05961L4.62137 4.5149C4.84396 3.62458 5.64392 3 6.56165 3H10.0001M5.00012 11V19C5.00012 20.1046 5.89555 21 7.00012 21H10.0001M14.0001 21V17C14.0001 15.8954 13.1047 15 12.0001 15V15C10.8955 15 10.0001 15.8954 10.0001 17V21M14.0001 21H10.0001M14.0001 3H10.0001"
		stroke="url(#paint0_linear_1137_35075)"
		stroke-linejoin="round"
	/>
	<defs>
		<linearGradient
			id="paint0_linear_1137_35075"
			x1="3"
			y1="3"
			x2="21.1038"
			y2="5.83202"
			gradientUnits="userSpaceOnUse"
		>
			<stop stop-color="#D7DBFF" />
			<stop offset="1" stop-color="#C2C8FF" />
		</linearGradient>
	</defs>
</svg>
