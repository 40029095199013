export enum KeyboardEventTypes {
	arrowDown = 'ArrowDown',
	arrowUp = 'ArrowUp',
	enter = 'Enter',
	escape = 'Escape',
	tab = 'Tab',
	arrowLeft = 'ArrowLeft',
	arrowRight = 'ArrowRight',
	home = 'Home',
	end = 'End',
	space = 'Space'
}
