interface ClickOutsideOptions {
	enabled: boolean;
	callback: (event: MouseEvent) => void;
}

function clickOutside(
	node: HTMLElement,
	{ enabled: initialEnabled, callback }: ClickOutsideOptions
) {
	const handleClick = (event: MouseEvent) => {
		if (node && !node.contains(event.target as Node) && !event.defaultPrevented) {
			callback(event);
		}
	};

	if (initialEnabled) {
		setTimeout(() => {
			document.addEventListener('click', handleClick);
		}, 0);
	}

	return {
		update(enabled: boolean) {
			if (enabled) {
				document.addEventListener('click', handleClick);
			} else {
				document.removeEventListener('click', handleClick);
			}
		},
		destroy() {
			document.removeEventListener('click', handleClick);
		}
	};
}

export { clickOutside };
