import type { Coordinates, LocationType } from '$types/booking.types';
import type { Bounds } from '$types/map.types';

export function inferType(types: string[]): LocationType {
	const typeMap: Record<string, LocationType> = {
		airport: 'airport',
		lodging: 'hotel',
		tourist_attraction: 'beach',
		natural_feature: 'beach',
		bakery: 'store',
		bicycle_store: 'store',
		book_store: 'store',
		clothing_store: 'store',
		convenience_store: 'store',
		department_store: 'store',
		electronics_store: 'store',
		furniture_store: 'store',
		hardware_store: 'store',
		jewelry_store: 'store',
		store: 'store',
		shopping_mall: 'store',
		pet_store: 'store',
		liquor_store: 'store'
	};
	for (const type of types) {
		const mappedType = typeMap[type];
		if (mappedType) {
			return mappedType;
		}
	}
	return 'point_of_interest';
}

export function inferCity(addressComponents: google.maps.GeocoderAddressComponent[]) {
	const city = addressComponents.find((component) => component.types.includes('locality'));
	return city?.long_name;
}

export function isCoordinateWithinBounds(coords: Coordinates, bounds: Bounds) {
	const { lat, lng } = coords;
	const { north, south, east, west } = bounds;

	if (lat >= south && lat <= north && lng >= west && lng <= east) {
		return true;
	}

	return false;
}
