<script lang="ts">
	import type { AutocompletePrediction } from '$types/autocomplete.types';

	import PlaneIcon from '$lib/icons/pickup-details/plane-icon.svelte';
	import LocationIcon from '$lib/icons/pickup-details/location-icon.svelte';
	import HotelIcon from '$lib/icons/pickup-details/hotel-icon.svelte';
	import StoreIcon from '$lib/icons/pickup-details/store-icon.svelte';
	import BeachIcon from '$lib/icons/pickup-details/beach-icon.svelte';
	import { PALMA_AIRPORT_PLACE_ID } from '$lib/maps/defaults';

	export let value: AutocompletePrediction;
	export let isFocused = false;
</script>

<button
	class="border-b-grey-200 auto-complete-result relative flex flex-row flex-nowrap items-center space-x-3 border-b p-3 pb-[6px]"
	class:bg-grey-200={isFocused}
	class:bg-opacity-80={isFocused}
	on:click
	aria-label="Select pickup location"
	tabindex="-1"
>
	{#if value.type === 'airport'}
		<PlaneIcon />
	{:else if value.type === 'point_of_interest'}
		<LocationIcon />
	{:else if value.type === 'hotel'}
		<HotelIcon />
	{:else if value.type === 'store'}
		<StoreIcon />
	{:else if value.type === 'beach'}
		<BeachIcon />
	{/if}
	<div class="grow-1 flex flex-col items-start space-y-1">
		<p
			class="rubik-regular-base color-grey-800 dark:text-grey-100 line-clamp-1 text-start leading-5"
		>
			{value.structured_formatting.main_text}
		</p>
		<p class="rubik-regular-sm color-grey-500 line-clamp-1 text-start leading-5">
			{value.structured_formatting.secondary_text || 'Palma, Spain'}
		</p>
	</div>
	{#if value.place_id === PALMA_AIRPORT_PLACE_ID}
		<span class="rubik-regular-sm color-white py-1px bg-linear-purple-100 rounded-full px-2"
			>Popular</span
		>
	{/if}
</button>
